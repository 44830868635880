import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
  "path": "/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/",
  "dateChanged": "2023-01-23",
  "author": "Mike Polinowski",
  "excerpt": "How can I use the the ioBroker MQTT Adapter as the Alarmserver of my WQHD 2k+ Camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker MQTT Alarmserver for your WQHD Camera' dateChanged='2023-01-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use the the ioBroker MQTT Adapter as the Alarmserver of my WQHD 2k+ Camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/' locationFR='/fr/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "iobroker-mqtt-alarmserver-for-your-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-mqtt-alarmserver-for-your-wqhd-camera",
        "aria-label": "iobroker mqtt alarmserver for your wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker MQTT Alarmserver for your WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How can I use the the ioBroker MQTT Adapter as the Alarmserver of my WQHD 2k+ Camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by installing the MQTT Client or Broker/Client Adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/844cc/Using_ioBroker_with_your_WQHD_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABN0lEQVQY0zWOS0/CQBRGu3EhicAgdEpNS5Fp59nHULGUSAwGKxoNhMSlCzbGoKKGYNxgcOPe6A/WwXC+m5vcxcl3tYrtFnNbVlXv90+zLBsMBmlbkSRJO0nSttpqNqRrhqNhr9fT4vE0GE1JdyijUEoZBAFljAk/7hxHrQ5mgQhlGB+KsOmHUjBKMPEjv5W2CMHa2ct39/FHXN0yioXwXRdl59eTxdfJ3eri/uNy/nkzeVq+rR5ms9fF83z5HscHhBDBhed5WsMyKvnt3XyOcc6oCqWCBrLBfOZH9eYRoUJGEaHYcWpIneozxtlaRm7V3INGlXP2D/awiPh+wykBUAbFmm1DC5bKpUK+CAo7GHtcsZYRQhBCXdf5xkYIEULrTh0AYJqmZVmWbRuGAQCA0MAYq2LO/+RfsJ1ENlIIgYEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e41ef9a9d96b58a9f25d12c119417ae/e4706/Using_ioBroker_with_your_WQHD_Camera_01.avif 230w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/d1af7/Using_ioBroker_with_your_WQHD_Camera_01.avif 460w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/7f308/Using_ioBroker_with_your_WQHD_Camera_01.avif 920w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/ff569/Using_ioBroker_with_your_WQHD_Camera_01.avif 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e41ef9a9d96b58a9f25d12c119417ae/a0b58/Using_ioBroker_with_your_WQHD_Camera_01.webp 230w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/bc10c/Using_ioBroker_with_your_WQHD_Camera_01.webp 460w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/966d8/Using_ioBroker_with_your_WQHD_Camera_01.webp 920w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/83135/Using_ioBroker_with_your_WQHD_Camera_01.webp 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e41ef9a9d96b58a9f25d12c119417ae/81c8e/Using_ioBroker_with_your_WQHD_Camera_01.png 230w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/08a84/Using_ioBroker_with_your_WQHD_Camera_01.png 460w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/c0255/Using_ioBroker_with_your_WQHD_Camera_01.png 920w", "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/844cc/Using_ioBroker_with_your_WQHD_Camera_01.png 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e41ef9a9d96b58a9f25d12c119417ae/c0255/Using_ioBroker_with_your_WQHD_Camera_01.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT Broker`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d6af3a8fab556c63ed67a69837503ba/d1d24/Using_ioBroker_with_your_WQHD_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACB0lEQVQoz22QXW/SUByH+QB9gb4AiqPthLUrZYNoYjYgLW8bjJZyel7aQttE/QBemHilmZmJ3npj4hfQXfglDacT0Pjkd/uc559TsCyrZVmtU+PseX/46ksv/XSZ/GfOy892+vHUOr+Zz1RVq9ePFFUrmKZptlqG3nx2Mdrc3kcf7sn7/fDDfmZ3v7LbH/Xjk5Nmo1qtlsvlx0+OCi2KYRid8zO4cgnwVu51PuDOgEfnXgPfWwa4LIssx/E8zzCMIMpb2TTNrdztBhBBhOP1Js2yJE0RwhAhTEKESRBAfwUqlQpPYVlWECV6di53OgihOI6TNN2s11EUYYwRQhhjCGGSJGEYCoLAMAzLsgzDiNJhudMhhERRRAhBCAKK7/sLiud5y+VSlmWWZTlaFsW/ZYxxGEWY4IACAPA8L5dd193JPM8Xi8Vt+fBsQkhMyWUIIaIEQZBlWRzHoijyPF8qlWRJkqTywW//kTF+KO+egBBijAEAgiBwHCdJUrHIi7lsmqau6/nZJAwBALnj+/5kejWeTCfj8XQ0upnPa7WaJEkypVJ59O9v54XcX3reyO45/YtLx37hOJPZzNB1TdOOKYqi7cvdbjeXKQTDACev43ffwrdf7+D6+zJ44wO12VAVRaXUFW1fbrfbw+HQ2WHbzvhq6CX2IoYDJ+0NFv2B1niqqapGqavab6H/vH8LyAowAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d6af3a8fab556c63ed67a69837503ba/e4706/Using_ioBroker_with_your_WQHD_Camera_02.avif 230w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/d1af7/Using_ioBroker_with_your_WQHD_Camera_02.avif 460w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/7f308/Using_ioBroker_with_your_WQHD_Camera_02.avif 920w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/ce1e0/Using_ioBroker_with_your_WQHD_Camera_02.avif 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d6af3a8fab556c63ed67a69837503ba/a0b58/Using_ioBroker_with_your_WQHD_Camera_02.webp 230w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/bc10c/Using_ioBroker_with_your_WQHD_Camera_02.webp 460w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/966d8/Using_ioBroker_with_your_WQHD_Camera_02.webp 920w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/b0d42/Using_ioBroker_with_your_WQHD_Camera_02.webp 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d6af3a8fab556c63ed67a69837503ba/81c8e/Using_ioBroker_with_your_WQHD_Camera_02.png 230w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/08a84/Using_ioBroker_with_your_WQHD_Camera_02.png 460w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/c0255/Using_ioBroker_with_your_WQHD_Camera_02.png 920w", "/en/static/0d6af3a8fab556c63ed67a69837503ba/d1d24/Using_ioBroker_with_your_WQHD_Camera_02.png 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d6af3a8fab556c63ed67a69837503ba/c0255/Using_ioBroker_with_your_WQHD_Camera_02.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b238ffac652368049863aad9323d0f32/91b29/Using_ioBroker_with_your_WQHD_Camera_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABr0lEQVQoz23Qz2+TYBzH8d1LwYYHnrYJHto5sOyAo1GxpfSB2lKBFXie5wvV/tjBHb148KIxcV6Mya56N5mJ/pVmbOuc8Z3P9ZXnx95hVe+hbjmBf3o+PPky2P5n5PXX8fbMOLTm4azT6Wqa1u1290zT7JmmcbD/ePxi9elX8eGCv/97P6tdbD//3nz8cb+jHzzYbzZbqqpqmnaJTdPUDcM+esSyBLIkS8LrxbM0mqXRNI2eL46TOGVYQUK9LkmSIAiqqt5gXe/3+4zxnDLGORQF58AYf7VabbYnq/UaoFikGW5iqeoa96p0Xbdtm11CTinNq7Isi+M4qgrDcD6fK4pSq9VEUbw9eYfLsiyXyytPKY2iaDAYOI7ztGoymWCMBUG4c+0dBoCiKOAGJ0kyJr7reSPX9Vx3Op1ijOvVmyVJusWGYdi2zTkHAEppmqZ5nidx5I8cMnSeEfLEJ0E4U1VVFMVGo4EQwhjf+TAAuPIABTAK69OX774Vb8/P8vL7In9znN5DSBJFRVH+PdmyrCAIyK6xRyYzf7Eh8ZKNyMb1oqGLWy0kywghWZbb7fYfiMePP6Fl6i4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b238ffac652368049863aad9323d0f32/e4706/Using_ioBroker_with_your_WQHD_Camera_03.avif 230w", "/en/static/b238ffac652368049863aad9323d0f32/d1af7/Using_ioBroker_with_your_WQHD_Camera_03.avif 460w", "/en/static/b238ffac652368049863aad9323d0f32/7f308/Using_ioBroker_with_your_WQHD_Camera_03.avif 920w", "/en/static/b238ffac652368049863aad9323d0f32/01a13/Using_ioBroker_with_your_WQHD_Camera_03.avif 983w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b238ffac652368049863aad9323d0f32/a0b58/Using_ioBroker_with_your_WQHD_Camera_03.webp 230w", "/en/static/b238ffac652368049863aad9323d0f32/bc10c/Using_ioBroker_with_your_WQHD_Camera_03.webp 460w", "/en/static/b238ffac652368049863aad9323d0f32/966d8/Using_ioBroker_with_your_WQHD_Camera_03.webp 920w", "/en/static/b238ffac652368049863aad9323d0f32/b4d37/Using_ioBroker_with_your_WQHD_Camera_03.webp 983w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b238ffac652368049863aad9323d0f32/81c8e/Using_ioBroker_with_your_WQHD_Camera_03.png 230w", "/en/static/b238ffac652368049863aad9323d0f32/08a84/Using_ioBroker_with_your_WQHD_Camera_03.png 460w", "/en/static/b238ffac652368049863aad9323d0f32/c0255/Using_ioBroker_with_your_WQHD_Camera_03.png 920w", "/en/static/b238ffac652368049863aad9323d0f32/91b29/Using_ioBroker_with_your_WQHD_Camera_03.png 983w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b238ffac652368049863aad9323d0f32/c0255/Using_ioBroker_with_your_WQHD_Camera_03.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in ioBroker configure the MQTT Adapter in `}<strong parentName="p">{`Client Mode`}</strong>{` and add your cameras IP and the MQTT port and login you configured in the previous step:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92118ebbe458cd0922cd521664886011/d1d24/Using_ioBroker_with_your_WQHD_Camera_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACPUlEQVQoz13LSU8TYRzH8YkXTzoDslimdGY6fZbO0ln6LLOVaTudgywJ0AIBFRJK0OiBU9FwFdRDr7wD4UDk6sH44hRqSfWbz+H5P8lPmDXjZ3PPpyQRQtRqN9vt9ov7OOeMseA+znkwjjGWpunh4WGSJIKiY1XTZmZnlFKpv/9uZXk1y7JOnqdp2mw2Kac84lEU0Yk454SQPM8FmyYIwWnp6UKhsNM7bnaW4zjOsixNU84Dv+7XaZ0S6k3kOM7BwcHZ2ZmwMfy58uVH8+KX2z0BCGDLMarVKkYYQVjRMQQIAAQBRvABgmB7q7ezvS2krwd85wSvvAG8E8VJo5WzqGH7zCHBiEtDy6P/gaarVYCgyvPKwtyTx48K8/Ok+57ufqC7g6h/Hh1djMRHn8P++b8+RcdfzaAllHW9pCjS1LQsF8OkwaPE8eqG7Vg116q5pu14hAZxwxz/WDXXsGweJUEYCqqqlUolURRlWW6EjLqWiXQTlk2gPbCxPnkaFdWpAtsyBJunqla+GxcX66v7/vpbb61Ptk7Yy1O2N+B7p3zvlO3+fYwN+KuPJlsSnDopFhfvx0Web3jZhtde97JNP+95na6bbbrZptfpjXVH/HzLcIlAVV3TNEmSZFluLSW2gZCuYVCGumpiEFKfuDYG5QfVMYygsKhAXdclSVqQ5SRJwjAihFBKCaGEMs655/m2XRuxLMswzRGMsTA1e5coioqiXF5e3tzcfLu6ur6+vr39PhwOK5WKOZEx0Z/xbygLq48SjlhBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92118ebbe458cd0922cd521664886011/e4706/Using_ioBroker_with_your_WQHD_Camera_04.avif 230w", "/en/static/92118ebbe458cd0922cd521664886011/d1af7/Using_ioBroker_with_your_WQHD_Camera_04.avif 460w", "/en/static/92118ebbe458cd0922cd521664886011/7f308/Using_ioBroker_with_your_WQHD_Camera_04.avif 920w", "/en/static/92118ebbe458cd0922cd521664886011/ce1e0/Using_ioBroker_with_your_WQHD_Camera_04.avif 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92118ebbe458cd0922cd521664886011/a0b58/Using_ioBroker_with_your_WQHD_Camera_04.webp 230w", "/en/static/92118ebbe458cd0922cd521664886011/bc10c/Using_ioBroker_with_your_WQHD_Camera_04.webp 460w", "/en/static/92118ebbe458cd0922cd521664886011/966d8/Using_ioBroker_with_your_WQHD_Camera_04.webp 920w", "/en/static/92118ebbe458cd0922cd521664886011/b0d42/Using_ioBroker_with_your_WQHD_Camera_04.webp 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92118ebbe458cd0922cd521664886011/81c8e/Using_ioBroker_with_your_WQHD_Camera_04.png 230w", "/en/static/92118ebbe458cd0922cd521664886011/08a84/Using_ioBroker_with_your_WQHD_Camera_04.png 460w", "/en/static/92118ebbe458cd0922cd521664886011/c0255/Using_ioBroker_with_your_WQHD_Camera_04.png 920w", "/en/static/92118ebbe458cd0922cd521664886011/d1d24/Using_ioBroker_with_your_WQHD_Camera_04.png 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92118ebbe458cd0922cd521664886011/c0255/Using_ioBroker_with_your_WQHD_Camera_04.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When you connect ioBroker to your camera's MQTT broker all it's state data points will automatically be registered in ioBroker's object tree (`}<strong parentName="p">{`Note`}</strong>{` that you have to use the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#status-command-or-raw-topics"
      }}>{`Command Topics and not the State Topics`}</a>{` if you want to update your camera's state):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e81aceca2fc5aa89e73c03214899da68/58bb7/Using_ioBroker_with_your_WQHD_Camera_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACEElEQVQoz22SzU8TQRjG5+CJmNCtLV1gW7rtfs07Mzs7X9v9KlCC0EYSoiYiCQGiiSaaaKgcJBEiF49q9ObB+Kdq4UZ98st7e/I7PC9a6UHH7TcbDcdxyrKsqqosy7wsTZpqk/JECCGklEKIOI4550mSFEUxmUy01ghEBpQvtVq2vSwSnueZUKYPiR4MimoYYBBJkmUZY4wQKqVSSjEW8zgWQqAo8P2uU1+416xbaTFMEg4ARuq9yWPGKGPsVsgY4zeJokgpxTn3fR+NP/7ePv+VT//Q/bciLaXUGEDJ9NmT4wgDEApAMCFACMDsRhiqaiilCsIQbb77MXr/U77+DjtHodcvqnXDIXCdoLsa9Tt36XVCt729WcWR53XbaNlaWKnfX0BoyV7Jji7WTz+Vh1NzcDZ4Pv0v6cHZ3pvr4ckF3zlE7XbH7XlWrbbq0fzkqjj9vP/h2/DldXZ8mZ9czZMdXz49/7r56kvy6AUCVXohrtVqraVm5DpAaGaUokHYdSK3PU/YddZzw4Ket+YgJmTXdWuLi7a9DJQyU40eTkZbW4QymINQhgndHU+EUn4YIbPW8z3PsizbtgEwEbN9U2MijGEuGABjvLGxwTkPggCttr1+36vXrZZtA8axLkfbu1rPPuFOkwKwm/J4PBZCzHa2Gs0HjcatGWMshEjT1Bijtb7jZADippzneRzH/8x/AY4XjwO51eWsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e81aceca2fc5aa89e73c03214899da68/e4706/Using_ioBroker_with_your_WQHD_Camera_05.avif 230w", "/en/static/e81aceca2fc5aa89e73c03214899da68/d1af7/Using_ioBroker_with_your_WQHD_Camera_05.avif 460w", "/en/static/e81aceca2fc5aa89e73c03214899da68/7f308/Using_ioBroker_with_your_WQHD_Camera_05.avif 920w", "/en/static/e81aceca2fc5aa89e73c03214899da68/01068/Using_ioBroker_with_your_WQHD_Camera_05.avif 985w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e81aceca2fc5aa89e73c03214899da68/a0b58/Using_ioBroker_with_your_WQHD_Camera_05.webp 230w", "/en/static/e81aceca2fc5aa89e73c03214899da68/bc10c/Using_ioBroker_with_your_WQHD_Camera_05.webp 460w", "/en/static/e81aceca2fc5aa89e73c03214899da68/966d8/Using_ioBroker_with_your_WQHD_Camera_05.webp 920w", "/en/static/e81aceca2fc5aa89e73c03214899da68/8a0f1/Using_ioBroker_with_your_WQHD_Camera_05.webp 985w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e81aceca2fc5aa89e73c03214899da68/81c8e/Using_ioBroker_with_your_WQHD_Camera_05.png 230w", "/en/static/e81aceca2fc5aa89e73c03214899da68/08a84/Using_ioBroker_with_your_WQHD_Camera_05.png 460w", "/en/static/e81aceca2fc5aa89e73c03214899da68/c0255/Using_ioBroker_with_your_WQHD_Camera_05.png 920w", "/en/static/e81aceca2fc5aa89e73c03214899da68/58bb7/Using_ioBroker_with_your_WQHD_Camera_05.png 985w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e81aceca2fc5aa89e73c03214899da68/c0255/Using_ioBroker_with_your_WQHD_Camera_05.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now that your camera is connected we can start `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`Testing the MQTT Alarmserver`}</a>{`. Whenever an alarm is triggered on your camera both the trigger as well - if the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Object_Detection/"
      }}>{`Object Detection`}</a>{` is activated - as the detected object will be published by the MQTT service.`}</p>
    <p><strong parentName="p">{`Alarm Trigger`}</strong></p>
    <p><em parentName="p">{`MQTT Topic`}</em>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered`}</code></p>
    <ul>
      <li parentName="ul">{`Alarm Area 1 triggered: &active=1 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></li>
      <li parentName="ul">{`Alarm Area 2 triggered: &active=2 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"2"}`}</code></li>
      <li parentName="ul">{`Alarm Area 3 triggered: &active=3 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"3"}`}</code></li>
      <li parentName="ul">{`Alarm Area 4 triggered: &active=4 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"4"}`}</code></li>
      <li parentName="ul">{`Alarm-In / PIR triggered: &active=5 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"5"}`}</code></li>
      <li parentName="ul">{`Audio Alarm triggered: &active=6 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"6"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 1 triggered: &active=7 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"7"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 2 triggered: &active=8 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"8"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 3 triggered: &active=9 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"9"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 4 triggered: &active=10 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"10"}`}</code></li>
      <li parentName="ul">{`Manual trigger (cmd=pushhostalarm): &active=99 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"99"}`}</code></li>
    </ul>
    <p><strong parentName="p">{`Detected Object`}</strong></p>
    <p><em parentName="p">{`MQTT Topic`}</em>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered/object`}</code></p>
    <ul>
      <li parentName="ul">{`Person: &object=1 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></li>
      <li parentName="ul">{`Vehicles: &object=2 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"2"}`}</code></li>
      <li parentName="ul">{`Person + Vehicles: &object=3 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"3"}`}</code></li>
      <li parentName="ul">{`Animals: &object=4 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"4"}`}</code></li>
      <li parentName="ul">{`Person + Animals: &object=5 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"5"}`}</code></li>
      <li parentName="ul">{`Person + Vehicles: &object=6 -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"6"}`}</code></li>
    </ul>
    <p>{`You can test this service by walking in front of your camera. Or, more conveniently, by using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`MQTT Alarmserver Testing Command`}</a>{`. For example if you want to simulate `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`Alarm Area 3`}</a>{` triggering and you camera detecting both a person and a car you can use the following CGI command:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/param.cgi?cmd=testmqtt&active=4&object=3&user=admin&pwd=instar`}</code></li>
    </ul>
    <p>{`Before sending the command both `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered`}</code>{` topics will have the value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`. Meaning the MQTT Alarmserver is idle:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/73caa/Using_ioBroker_with_your_WQHD_Camera_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABsklEQVQoz32QzWsTQRTAVw+KWLKmIVUam3S7mY+d2dnd2dl9M/vBZo1oNqV6CBh6EIlUQajWmxdBrPQmqOAHHr3o3ylpRQoRf/wOj8f74lldlKxv3Oist2Uc3z1ldzrVWgOAMeYs0OcAgKIo5vN5VVUW5iGmrNPdQAgVRa5NxqIUsgJMjgiRMpJSslM8z+OcSynjOFZKaa0tzjly+q3LF4YIpSn4whciCMNQBEFuSgAthIii5QghhFIqCALOeRgGjDGrfvI2X7zxH7xieQOmOMsyxjDGs719YwpCMOf873JKqZSyLEuMsTV68ak8/BgdvKdQS6VT0J7nsT+1bBVKaZIkWZYRQqzB5vXNbnvt0sXB1k1pKjAZ+y+UUgAoy3LZvIPItuNea3d2HMcP4qKeGA3L3zD2Twkhxpi6rpdn8yTf2nZs23YGfUYxT0yUqCFBiOJVsUcIcpUMjU4xci3JWK/Xs1st1xOhqe9Uu7NxM1bZrdisOpLaV4UZN6PJfU9qy+8PXXdoX73C9p42L78/OjhZvPt57+hrc/hl+vy8nydH3x4+Prn97EPz+sfs+Bcsjn8Dh0t7Y5uaR4oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6258d422a2c89dd2694f7be5ab0e81ca/e4706/Using_ioBroker_with_your_WQHD_Camera_06.avif 230w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/d1af7/Using_ioBroker_with_your_WQHD_Camera_06.avif 460w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/7f308/Using_ioBroker_with_your_WQHD_Camera_06.avif 920w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/a53e8/Using_ioBroker_with_your_WQHD_Camera_06.avif 1110w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6258d422a2c89dd2694f7be5ab0e81ca/a0b58/Using_ioBroker_with_your_WQHD_Camera_06.webp 230w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/bc10c/Using_ioBroker_with_your_WQHD_Camera_06.webp 460w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/966d8/Using_ioBroker_with_your_WQHD_Camera_06.webp 920w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/f9357/Using_ioBroker_with_your_WQHD_Camera_06.webp 1110w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6258d422a2c89dd2694f7be5ab0e81ca/81c8e/Using_ioBroker_with_your_WQHD_Camera_06.png 230w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/08a84/Using_ioBroker_with_your_WQHD_Camera_06.png 460w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/c0255/Using_ioBroker_with_your_WQHD_Camera_06.png 920w", "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/73caa/Using_ioBroker_with_your_WQHD_Camera_06.png 1110w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6258d422a2c89dd2694f7be5ab0e81ca/c0255/Using_ioBroker_with_your_WQHD_Camera_06.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you send the command above the values will switch to represent the cause of the alarm trigger:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/42c23198019190a94e45881ffe5b6d5b/2a077/Using_ioBroker_with_your_WQHD_Camera_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABUUlEQVQY03XNT0vCYADH8R26dJFJ/y6K5dqcj3v2bM+zZ3v2z2mCE4WCDtEhAy/SG+iQQQXdhF6BS+nWrXfkIPVdKIuMwMq+/K4fflya5xFCYRhWj2qe51PTpJRalkUpJYQEQdBoNHRsqIgARStBHVNbI8xyAsevcjvpVP5gnzEL6gamJjFMCKFhGIqi6LqOMQYA9Pv98TiO43gymbwvm06ns9mME+xWEbsYkxIAzfqJ8h2EEACAMRYEYTgcJkkyn8+Tn3G0+yTbdYOaqooQ0sFKsizbti2KYhRF63FqcyOXzRDma5omy/Iv7LquJEn/4u3dvVw2oyLdcX1QEIsrK4iC5zDpMB8NBusxz/OCDLVys9w6w14NsopqV7+mWEGleVqi/vPoZYkXf563+IJ/7Hbuw+6D37mzLm5Yu8faPfvylrV79atHcn49en37xIvf+AOwENw1C+shAQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/42c23198019190a94e45881ffe5b6d5b/e4706/Using_ioBroker_with_your_WQHD_Camera_07.avif 230w", "/en/static/42c23198019190a94e45881ffe5b6d5b/d1af7/Using_ioBroker_with_your_WQHD_Camera_07.avif 460w", "/en/static/42c23198019190a94e45881ffe5b6d5b/7f308/Using_ioBroker_with_your_WQHD_Camera_07.avif 920w", "/en/static/42c23198019190a94e45881ffe5b6d5b/e1c99/Using_ioBroker_with_your_WQHD_Camera_07.avif 1380w", "/en/static/42c23198019190a94e45881ffe5b6d5b/1f1b4/Using_ioBroker_with_your_WQHD_Camera_07.avif 1533w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/42c23198019190a94e45881ffe5b6d5b/a0b58/Using_ioBroker_with_your_WQHD_Camera_07.webp 230w", "/en/static/42c23198019190a94e45881ffe5b6d5b/bc10c/Using_ioBroker_with_your_WQHD_Camera_07.webp 460w", "/en/static/42c23198019190a94e45881ffe5b6d5b/966d8/Using_ioBroker_with_your_WQHD_Camera_07.webp 920w", "/en/static/42c23198019190a94e45881ffe5b6d5b/445df/Using_ioBroker_with_your_WQHD_Camera_07.webp 1380w", "/en/static/42c23198019190a94e45881ffe5b6d5b/739e4/Using_ioBroker_with_your_WQHD_Camera_07.webp 1533w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/42c23198019190a94e45881ffe5b6d5b/81c8e/Using_ioBroker_with_your_WQHD_Camera_07.png 230w", "/en/static/42c23198019190a94e45881ffe5b6d5b/08a84/Using_ioBroker_with_your_WQHD_Camera_07.png 460w", "/en/static/42c23198019190a94e45881ffe5b6d5b/c0255/Using_ioBroker_with_your_WQHD_Camera_07.png 920w", "/en/static/42c23198019190a94e45881ffe5b6d5b/b1001/Using_ioBroker_with_your_WQHD_Camera_07.png 1380w", "/en/static/42c23198019190a94e45881ffe5b6d5b/2a077/Using_ioBroker_with_your_WQHD_Camera_07.png 1533w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/42c23198019190a94e45881ffe5b6d5b/c0255/Using_ioBroker_with_your_WQHD_Camera_07.png",
              "alt": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "title": "ioBroker MQTT Alarmserver for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      